const appinfo: { build: number, version: string } = require("../../appInfo.json");

export const environment = {
  environmentName: 'production',
  production: true,
  appVersion: appinfo.version,
  PDFTronLicense: "Datalex Software AS (datalexsoftware.no):OEM:Datalex Lawyer WebApp::B+:AMS(20240814):33A579620477760A0360B13AC9A2537860616FD39706FDB24E541DF454F518F65482B6F5C7",
  appInsightsConfig: {
    connectionString: "InstrumentationKey=4765c2de-8132-4f06-927a-ef89423ce705;IngestionEndpoint=https://norwayeast-0.in.applicationinsights.azure.com/;LiveEndpoint=https://norwayeast.livediagnostics.monitor.azure.com/"
  }
};
